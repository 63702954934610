import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Modal } from '@forma/forma-ui-kit';
import SearchInput from 'components/SearchInput';
import Dropdown from '../Dropdown';
import SelectSwitcher from '../SelectSwitcher';

import styles from './variable-select-modal.module.css';

const VariableSelectModal = ({
  open, onClose, variablesGroups, availableVariables, onToggleVar, selectedVariables, icon
}) => {
  const { t } = useTranslation();
  const [ searchValue, setSearchValue ] = useState('');

  const groupsArray = variablesGroups &&
    Object.values(variablesGroups).reduce((acc, { groups }) => [ ...acc, ...groups ], []);

  const handleClose = () => {
    setSearchValue('');
    onClose();
  };

  return (
    <Modal
      open={open}
      title={t('add_variable')}
      onClose={handleClose}
    >
      <div className={styles.modalSelect}>
        <SearchInput placeholder={t('enter_variable_name')} onSearch={setSearchValue} />
 
        {searchValue ? (
          <div className={classNames(styles.searchResults, 'styled-scrollbar')}>
            {availableVariables && Object.values(availableVariables)
              .filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()))
              .map(variable => {
                const group = groupsArray?.find(({ items }) => items.includes(variable.id));
                return (
                  <div className={styles.dropdownSwitch} key={variable.id}>
                    <SelectSwitcher
                      icon={icon}
                      label={
                        <span className={styles.searchItemLabel}>
                          {variable.name}
                          <span className={styles.searchItemGroup}>
                            {group?.name}
                          </span>
                        </span>
                      }
                      onChange={checked => onToggleVar(variable, checked, group && { id: group.groupId, name: group.name })}
                      checked={!!selectedVariables?.includes(variable.id)}
                    />
                  </div>
                );
              })
            }
          </div>
        ) : (
          variablesGroups && Object.values(variablesGroups).map(({ id: scopeId, scope, groups, name }) => (
            <Dropdown id={`select_section_${scope}`} title={name} key={scope}>
              <>
                <div className={styles.groups}>
                  {groups?.map(({ groupId, name, items }) => (
                    <Dropdown
                      id={`variables_group_${groupId}`}
                      title={name}
                      viewStyle="white"
                      size="medium"
                      key={scope + groupId}
                    >
                      <div className={styles.dropdownSwitches}>
                        {availableVariables && items.map((id) => (
                          <div className={styles.dropdownSwitch} key={scope + groupId + id}>
                            <SelectSwitcher
                              // id for onboarding
                              containerId={`variable_container_${groupId}_${id}`}
                              icon={icon}
                              label={availableVariables[id].name}
                              onChange={checked => onToggleVar(availableVariables[id], checked, { id: groupId, name })}
                              checked={!!selectedVariables?.includes(id)}
                            />
                          </div>
                        ))}
                      </div>
                    </Dropdown>
                  ))}
                </div>
              </>
            </Dropdown>
          ))
        )}
      </div>
    </Modal>
  );
};

export default VariableSelectModal;
