import * as yup from 'yup';

const validationSchema = {
  login: yup.string().required(null).min(3, null).trim().email('validation_email'),
  name: yup.string().required(null).min(3, 'validation_name_length').max(250, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-ZЁё\- ]+$/, 'validation_name_symbols'),
  familyName: yup.string().required(null).min(3, null).max(50, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-Z ]+$/, 'validation_name_symbols'),
  firstName: yup.string().required(null).min(3, null).max(50, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-Z ]+$/, 'validation_name_symbols'),
  middleName: yup.string().required(null).min(3, null).max(50, null)
    .matches(/^[^0-9]+$/, 'validation_name_numbers')
    .matches(/^[а-яА-Яa-zA-Z ]+$/, 'validation_name_symbols'),
  phone: yup.string().required(null).transform(value => value.replace(/[^0-9]/g, '')).min(10, 'validation_phone'),
  oldPassword: yup.string().required(null).min(6, 'validation_password_length'),
  password: yup.string().required(null).min(8, 'validation_password_length')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'validation_password_symbols'),
  confirm_password: yup.string().required(null).min(8, null).oneOf([ yup.ref('password'), null ], 'validation_password_missmatch'),
  passwordLogin: yup.string().required(null).min(6, null),
  groupids: yup.string().required(null),
  social: yup.string().required(null),
};

const getValidationSchema = (fields) => yup.object().shape(
  // fields.reduce((prev, current) => ({ ...prev, [current === 'passwordLogin' ? 'password' : current]: validationSchema[current] }), {})
  Object.keys(fields).reduce((prev, current) => {
    let itemKey = null;
    let itemSchema = null;

    if (typeof fields[current] === 'object') {
      itemKey = current;
      // console.log(fields[current], Array.isArray(fields[current]))
      if (Array.isArray(fields[current])) {
        itemSchema = yup.array().of(getValidationSchema(fields[current])).min(1).required(); // array
      } else {
        itemSchema = getValidationSchema(fields[current]); // object
      }
    } else {
      itemKey = fields[current] === 'passwordLogin' ? 'password' : fields[current];
      itemSchema = validationSchema[fields[current]]; // string
    }

    return ({ ...prev, [itemKey]: itemSchema });
  }, {})
);

export default getValidationSchema;


