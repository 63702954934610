import { useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FileExplorer from 'components/FileExplorer';
import LoadingIndicator from 'components/LoadingIndicator';
import { Modal } from '@forma/forma-ui-kit';

// import { setNotification } from 'redux/notifications/notificationsSlice';
import {
  useAddSharedTemplateMutation,
  useGetSharedFoldersTreeQuery,
  useAddSharedFolderMutation,
  useRemoveSharedFolderMutation,
  useRemoveSharedTemplateMutation,
  useSaveSharedFolderMutation,
  useSaveSharedTemplateMutation,
  useImportSharedTemplateMutation,
  useCopySharedTemplateMutation,
  useGetSharedTemplatesQuery
} from 'store/templates/templatesApi';

import styles from './Templates.module.css';
import { Link } from 'react-router-dom';

const Templates = () => {
  const { t } = useTranslation();
  const [ openTemplate, setOpenTemplate ] = useState(null);
  const [ searchQuery, setSeachQuery ] = useState('');
  // const dispatch = useDispatch();

  const { data: searchResults, isLoading: isLoadingSearch } = useGetSharedTemplatesQuery({ query: searchQuery }, { skip: !searchQuery });
  const { data: foldersTree, isLoading, error: pageError } = useGetSharedFoldersTreeQuery();
  const [ addTemplate, { isLoading: isAddLoading } ] = useAddSharedTemplateMutation();
  const [ importTemplate, { isLoading: isImportLoading } ] = useImportSharedTemplateMutation();
  const [ saveTemplate, { isLoading: isSaveLoading } ] = useSaveSharedTemplateMutation();
  const [ removeTemplate, { isLoading: isRemoveLoading } ] = useRemoveSharedTemplateMutation();
  const [ copyTemplate, { isLoading: isCopyLoading } ] = useCopySharedTemplateMutation();
  const [ addFolder, { isLoading: isAddFolderLoading } ] = useAddSharedFolderMutation();
  const [ saveFolder, { isLoading: isSaveFolderLoading } ] = useSaveSharedFolderMutation();
  const [ removeFolder, { isLoading: isRemoveFolderLoading } ] = useRemoveSharedFolderMutation();

  const handleCreateElement = async ({ name, type, parentid, file }) => {
    if (type === 'folder') {
      addFolder({ name, parentid }).then(result => {
        if (!('data' in result)) return;
        // setUpdatedId(result.data.id ?? null);
        // setTimeout(() => setUpdatedId(null), 5000);
      });
    } else {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        if (parentid) formData.append('folderid', parentid);

        importTemplate(formData).then(result => {
          if (!('data' in result)) return;
          // setUpdatedId(result.data.id ?? null);
          // setTimeout(() => setUpdatedId(null), 5000);
        });
      } else {
        addTemplate({ name, folderid: parentid }).then(result => {
          if (!('data' in result)) return;
          // setUpdatedId(result.data.id ?? null);
          // setTimeout(() => setUpdatedId(null), 5000);
        });
      }
    }
  };

  const handleChangeElement = async ({ id, name, type, parentid }) => {
    if (type === 'folder') saveFolder({ id, name, parentid });
    else saveTemplate({ id, name, folderid: parentid });
  };
  const handleRemoveElement = async ({ id, type }) => {
    if (type === 'folder') removeFolder(id);
    else removeTemplate(id);
  };

  const handleCopyElement  = ({ id, targetId, name, type }) => {
    if (id === targetId) return null;
    if (type === 'folder') {
      addFolder({ parentid: targetId, name });
    } else {
      copyTemplate({ id, folderid: targetId });
    }
  };
  const handleMoveElement = ({ id, targetId, name, type }) => {
    if (id === targetId) return null;
    if (type === 'folder') {
      saveFolder({ id, parentid: targetId, name });
    } else {
      saveTemplate({ id, folderid: targetId, name });
    }
  };


  return (
    <AdminLayout title={t('templates')}>
      <PageTitle>{t('site_name') + ' – ' + t('templates')}</PageTitle>
      <FileExplorer
        foldersTree={foldersTree}
        // folder={folders}
        // userGroups={groups}
        // items={folders?.items}
        // active={openedFolder}
        // updated={updatedId}
        isLoading={isLoading || !!pageError}

        // subitems={openedFolder ? subfolders?.items : null}
        // subitemsIsLoading={isLoadingSubfolder}

        // actions={{
        //   onCopyLink: handleCopyLink,
        //   onFavouritesToggle: handleFavouritesToggle
        // }}
        search={{
          results: searchQuery !== '' && searchResults?.templates,
          isLoading: isLoadingSearch,
          onChange: setSeachQuery
        }}
        editing={{
          onMove: handleMoveElement,
          onCopy: handleCopyElement,
          onRemove: handleRemoveElement,
          onRename: handleChangeElement,
          onCreate: handleCreateElement,
        //   onChangeAccess: handleChangeAccess
        }}
        // favourites={{
        //   isOpen: !!isFavourites,
        //   href: isFavourites ? '/templates' : '/favourites'
        // }}
        navigation={{
        //   onClickBack: handleBackFolder,
        //   onShowFolderContent: handleShowContent,
        //   onOpenFolder: handleOpenFolder,
          onOpenTemplate: setOpenTemplate
        }}
      />
      <Modal
        open={!!openTemplate}
        onClose={() => setOpenTemplate(null)}
        title={openTemplate?.name}
        buttonsClassName={styles.modalButtons}
        buttons={[
          {
            as: Link,
            children: t('edit_template'),
            // viewStyle: 'tertiary',
            to: `/editor/${openTemplate?.id}`
          },
          {
            as: Link,
            children: t('edit_description'),
            // viewStyle: 'tertiary',
            to: `/templates/${openTemplate?.id}`
          },
          {
            as: Link,
            children: t('fill_template'),
            viewStyle: 'tertiary',
            to: `/fill-template/${openTemplate?.id}`
          }
        ]}
      >
        <div className={styles.modalContent} />
      </Modal>
      {(isAddLoading || isImportLoading || isSaveLoading || isRemoveLoading || isCopyLoading || isAddFolderLoading || isSaveFolderLoading || isRemoveFolderLoading) && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}
    </AdminLayout>
  )
};

export default Templates;